<template>
  <div>

    <BlockViewer header="Vendas por Colaborador"  >
      <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
        <div class="grid">
          <div class="col-12 md:col-6 lg:col-6">
            <div class="surface-card shadow-2 p-3 border-round">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3">Equipamentos</span>
                  <div class="text-900 font-medium text-xl">R$ 148.000,00</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                  <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
                </div>
              </div>
              <span class="text-green-500 font-medium">23 em estoque </span>
              <!--                            <span class="text-500">since last visit</span>-->
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-6">
            <div class="surface-card shadow-2 p-3 border-round">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3">Serviços</span>
                  <div class="text-900 font-medium text-xl">R$ 21.100,00</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
                  <i class="pi pi-map-marker text-orange-500 text-xl"></i>
                </div>
              </div>
              <span class="text-green-500 font-medium">%52+ </span>
              <span class="text-500">equipe disponível</span>
            </div>
          </div>


        </div>
      </div>
    </BlockViewer>



    <DataTable ref="dt" :value="representantes" v-model:selection="selectedRepresentantes" dataKey="id" :paginator="true" :rows="10"
               v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} representantes" responsiveLayout="scroll">
      <template #header>
        <div class="flex justify-content-between flex-column sm:flex-row">
          <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
          <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filters1['global'].value" placeholder="Pesquisar..." style="width: 100%"/>
                  </span>
        </div>
      </template>

      <Column field="representante" header="Representante" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">Representante</span>
          {{slotProps.data.representante}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo cliente"/>
        </template>
      </Column>

      <Column field="price" header="Valor" :sortable="true" class="text-right">
        <template #body="slotProps">
          <span class="p-column-title">Price</span>
          {{formatCurrency(slotProps.data.price)}}
        </template>
        <template #filter="{filterModel}">
          <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
        </template>
      </Column>
      <Column field="quantity" header="Potência" :sortable="true" class="text-right">
        <template #body="slotProps">
          <span class="p-column-title">Potência</span>
          {{formatDecimal(slotProps.data.quantity)}}
        </template>
        <template #filter="{filterModel}">
          <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
        </template>
      </Column>




    </DataTable>

  </div>

</template>

<script>
import BlockViewer from "@/BlockViewer";
import RepresentanteService from '@/service/LeadService';
import {FilterMatchMode, FilterOperator} from "primevue/api";


export default {
  name: 'Free',
  components: {BlockViewer},
  data() {
    return {
      representantes: null,
      selectedRepresentantes: null,
      loading1: true,
      filters1: null,
    }
  },
  representanteService: null,
  created() {
    this.representanteService = new RepresentanteService();
    this.initFilters();

  },
  mounted() {
    this.representanteService.getLeads().then((data) => {
      console.log('orcamentoColaborador mounted')
      this.loading1 = false
      this.representantes = data
    })
  },

  methods: {
    formatDecimal(value) {
      if (value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },

    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }

      this.filters1 = {
        'code': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},


        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'country.name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'representative': {value: null, matchMode: FilterMatchMode.IN},
        'category': {value: null, matchMode: FilterMatchMode.IN},
        'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
        'price': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
      }

    },


    clearFilter1() {
      this.initFilters();
    },
  }
}
</script>
